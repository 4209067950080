<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Contact us
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">Customer Service</h2>
          <p>Get in touch with our customer service team</p>

          <ul>
            <li><a href="https://info.rechargenews.com/re-customer-service-form">Contact us</a></li>
            <li>
              <div>Call Us:</div>
              <div class="pl-4">London: +44 207 645 2300 ,<br> Oslo: +47 2200 1200 ,<br> Stamford: +1 203 324 2994 ,<br> Singapore: +65 3165 0940</div>
            </li>
          </ul>
          <p>
            <strong>Subscribe</strong> - If you would like to enquire about a subscription to Recharge, or are having problems logging in please email <a href="mailto:subscribe@rechargenews.com">subscribe@rechargenews.com</a>
          </p>
          <p>
            <strong>Editorial</strong> - To contact our editorial team please email <a href="mailto:editorial@rechargenews.com">editorial@rechargenews.com</a>
          </p>
          <p>
            <strong>Sales</strong> - If you are interested in advertising in Recharge please contact <a href="mailto:sales@rechargenews.com">sales@rechargenews.com</a>
          </p>
          <p>
            <strong>Account</strong> - If you have questions about invoices please contact <a href="mailto:accounts@rechargenews.com">accounts@rechargenews.com</a>
          </p>
          <h2>Recharge offices:</h2>
          <p>
            <strong>OSLO</strong><br>
            Courier/visiting address:<br>
            Christian Krohgs gate 16,<br>
            Oslo, Norway<br>
            Mailing address:<br>
            PO Box 1182 Sentrum,<br>
            NO-0107 Oslo, Norway<br>
            T. +47 24 10 17 00
          </p>
          <p>
            <strong>LONDON</strong><br>
            7th Floor, 125 Wood Street<br>
            London, UK<br>
            EC2V 7AN<br>
            T. +44 207 645 2344
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'contactus-page',
  extends: Page
};
</script>
